import alt from '../alt';

class UserActions {
  constructor() {
    this.generateActions(
      'fetchError',
      'fetchSuccess',
      'listError',
      'listSuccess',
      'updateError',
      'updateSuccess'
    );
  }
}

export default alt.createActions(UserActions);
