import * as React from 'react';
import { Grid, TextField } from '@upperhand/playmaker';
import Actions from '../Actions';

const onSearchChange = (_event, value) => Actions.searchUpdated(value);

const Filters = ({ search }) => {
  return (
    <div style={{ padding: 4 }}>
      <Grid container spacing={1}>
        <TextField
          fullWidth
          icon="search"
          onChange={onSearchChange}
          outline={false}
          placeholder="Search name or email"
          rounded
          value={search}
        />
      </Grid>
    </div>
  );
};

export default Filters;
