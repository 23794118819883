import alt from '../../alt';

class UsersShowActions {
  constructor() {
    this.generateActions(
      'mounted',
      'tabSelected',
      'userFetchSuccess',
      'contactInfoEditEnabled',
      'contactInfoEditCancelled',
      'contactInfoEditCommitted',
      'userUpdated'
    );
  }
}

export default alt.createActions(UsersShowActions);
