import * as React from 'react';
import {
  Button,
  Card,
  Grid,
  TextField,
  Typography,
  Avatar,
} from '@upperhand/playmaker';
import moment from 'moment';
import Address from './Address';

const styles = {
  root: {
    padding: '16px 24px',
  },
};

const getAccountStatus = user => {
  if (user.last_login_at) return 'Claimed';
  if (user.invite_sent_at) return 'Invited';
  if (user.managed_by) return 'Managed User';

  return 'Unknown';
};

const Sidebar = ({
  user,
  editingUser,
  editingContactInfo,
  onContactEdit,
  onContactEditCancel,
  onContactEditCommit,
  onUserUpdate,
}) => (
  <div style={styles.root}>
    <Grid container spacing={1}>
      <Grid item container xs={12} spacing={1} alignItems="center">
        <Grid item>
          <Avatar>
            {user.first_name} {user.last_name}
          </Avatar>
        </Grid>
        <Grid item>
          <Typography variant="h4">
            {user.first_name} {user.last_name}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Card
          header="Contact Information"
          menuOptions={[{ label: 'Edit', onClick: onContactEdit }]}
        >
          <Grid container spacing={onContactEdit ? 2 : 1}>
            <Grid item xs={12}>
              {editingContactInfo ? (
                <TextField
                  label="Email"
                  onChange={(_e, value) =>
                    onUserUpdate({ keypath: ['email'], value })
                  }
                  outline
                  placeholder="Email"
                  value={editingUser.email}
                  fullWidth
                />
              ) : (
                <>
                  <Typography variant="body1">Email</Typography>
                  <Typography variant="subtitle2">
                    {user.email || 'Not Provided'}
                  </Typography>
                </>
              )}
            </Grid>

            <Grid item xs={12}>
              {editingContactInfo ? (
                <TextField
                  label="Phone Number"
                  onChange={(_e, value) =>
                    onUserUpdate({ keypath: ['phone'], value })
                  }
                  outline
                  placeholder="Phone Number"
                  value={editingUser.phone}
                  fullWidth
                />
              ) : (
                <>
                  <Typography variant="body1">Phone Number</Typography>
                  <Typography variant="subtitle2">
                    {user.phone || 'Not Provided'}
                  </Typography>
                </>
              )}
            </Grid>

            <Grid item xs={12}>
              {editingContactInfo ? (
                <Grid item container spacing={2}>
                  <Grid item container spacing={1}>
                    <Grid item xs={12}>
                      <TextField
                        label="Street Address"
                        onChange={(_e, value) =>
                          onUserUpdate({
                            keypath: ['address', 'line_1'],
                            value,
                          })
                        }
                        outline
                        placeholder="123 Main St"
                        value={editingUser.getIn(['address', 'line_1'])}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        onChange={(_e, value) =>
                          onUserUpdate({
                            keypath: ['address', 'line_2'],
                            value,
                          })
                        }
                        outline
                        placeholder="Apt 1"
                        value={editingUser.getIn(['address', 'line_2'])}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="City"
                      onChange={(_e, value) =>
                        onUserUpdate({
                          keypath: ['address', 'city'],
                          value,
                        })
                      }
                      outline
                      placeholder="Indianapolis"
                      value={editingUser.getIn(['address', 'city'])}
                      fullWidth
                    />
                  </Grid>
                  <Grid item container spacing={1}>
                    <Grid item xs={6}>
                      <TextField
                        label="State"
                        onChange={(_e, value) =>
                          onUserUpdate({
                            keypath: ['address', 'state'],
                            value,
                          })
                        }
                        outline
                        placeholder="IN"
                        value={editingUser.getIn(['address', 'state'])}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Zip"
                        onChange={(_e, value) =>
                          onUserUpdate({
                            keypath: ['address', 'postal_code'],
                            value,
                          })
                        }
                        outline
                        placeholder="12345"
                        value={editingUser.getIn(['address', 'postal_code'])}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <>
                  <Typography variant="body1">Address</Typography>
                  <Typography variant="subtitle2">
                    {user.address.id ? (
                      <Address address={user.address} />
                    ) : (
                      'Not Provided'
                    )}
                  </Typography>
                </>
              )}
            </Grid>

            {editingContactInfo && (
              <Grid item container xs={12} spacing={1}>
                <Grid item>
                  <Button onClick={onContactEditCommit}>Save</Button>
                </Grid>
                <Button type="tertiary" onClick={onContactEditCancel}>
                  Cancel
                </Button>
              </Grid>
            )}
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card header="Account Information">
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="body1">Account Status</Typography>
              <Typography variant="subtitle2">
                {getAccountStatus(user)}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body1">Last Login Time</Typography>
              <Typography variant="subtitle2">
                {user.last_login_at
                  ? moment(user.last_login_at).format('LLL[M]')
                  : 'N/A'}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body1">Registration Time</Typography>
              <Typography variant="subtitle2">
                {user.registered_at
                  ? moment(user.registered_at).format('LLL[M]')
                  : 'N/A'}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body1">Invitation Time</Typography>
              <Typography variant="subtitle2">
                {user.invite_sent_at
                  ? moment(user.invite_sent_at).format('LLL[M]')
                  : 'N/A'}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  </div>
);

export default Sidebar;
