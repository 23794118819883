import * as React from 'react';
import AltContainer from 'alt-container';
import { Button, Grid, TextField, Spinner } from '@upperhand/playmaker';

import LoginActions from './Actions';
import LoginStore from './Store';

import './Login.css';

export const submitOnEnter = event => {
  // Check if the key is the 'Enter' key.
  if (event.keyCode === 13) {
    LoginActions.login();
  }
};

const Content = ({
  loginStore: { email, password, authenticating, error },
}) => (
  <div className="Login fullHeight">
    <Grid container spacing={1} alignContent="center" className="fullHeight">
      {authenticating ? (
        <Grid item xs={12}>
          <div style={{ textAlign: 'center' }}>
            <Spinner />
          </div>
        </Grid>
      ) : (
        <>
          <Grid item container justify="center" xs={12}>
            <Grid item>
              <img
                src="https://upperhand-app.s3.amazonaws.com/static/images/UH_Logo_Web.png"
                alt="uh_logo"
              />
            </Grid>
          </Grid>
          <Grid item>
            <div onKeyDown={submitOnEnter}>
              <TextField
                onChange={(e, value) => LoginActions.emailUpdated(value)}
                outline
                placeholder="Email"
                value={email}
              />
            </div>
          </Grid>
          <Grid item>
            <div onKeyDown={submitOnEnter}>
              <TextField
                onChange={(e, value) => LoginActions.passwordUpdated(value)}
                outline
                placeholder="Password"
                type="password"
                value={password}
                errorText={error}
              />
            </div>
          </Grid>
          <Grid item>
            <Button onClick={() => LoginActions.login()}>Login</Button>
          </Grid>
        </>
      )}
    </Grid>
  </div>
);

const Login = props => (
  <AltContainer stores={{ loginStore: LoginStore }}>
    <Content {...props} />
  </AltContainer>
);

export default Login;
