import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { ThemeProvider as PmThemeProvider } from '@upperhand/playmaker';

import AppHeader from './containers/appHeader/AppHeader';
import Users from './containers/users/Users';
import UsersShow from './containers/usersShow/UsersShow';
import Clients from './containers/clients/Clients';
import Cookie from './utils/Cookie';
import Login from './containers/login/Login';

import './App.css';

const Routes = () => (
  <div style={{ height: 'calc(100% - 47px)' }}>
    <AppHeader />
    <Router>
      <Switch>
        <Route path="/" exact render={() => <Redirect to="/users" />} />
        <Route path="/users" exact component={Users} />
        <Route path="/users/:userId" exact component={UsersShow} />
        <Route path="/clients" exact component={Clients} />
      </Switch>
    </Router>
  </div>
);

const AuthenticatedApp = () => (Cookie.hasItem('jwt') ? <Routes /> : <Login />);

function App() {
  return (
    <div className="App">
      <PmThemeProvider>
        <AuthenticatedApp />
      </PmThemeProvider>
    </div>
  );
}

export default App;
