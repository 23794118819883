import alt from '../../alt';

class LoginActions {
  constructor() {
    this.generateActions(
      'mounted',
      'usersListSuccess',
      'usersListError',
      'searchUpdated'
    );
  }
}

export default alt.createActions(LoginActions);
