/* eslint-disable class-methods-use-this */
import * as paramString from 'jquery-param';

import Cookie from './utils/Cookie';

const API_HOST_MAP = {
  'admin.upperhand.io': 'api.upperhand.io',
  'athrep-admin.upperhand.io': 'athrep-api.upperhand.io',
  'conquer-admin.upperhand.io': 'conquer-api.upperhand.io',
  'dbat-admin.upperhand.io': 'api.dbathub.com',
  'f4f-admin.upperhand.io': 'f4f-api.upperhand.io',
  'kineticdrive-admin.upperhand.io': 'kineticdrive-api.upperhand.io',
  'qb-takeover-admin.upperhand.io': 'qb-takeover-api.upperhand.io',
  'sales-admin.upperhand.io': 'sales-api.upperhand.io',
  'sogility-admin.upperhand.io': 'sogility-api.upperhand.io'
};

const APP_HOST = window.location.hostname;
const API_HOST = API_HOST_MAP[APP_HOST];
const REQUEST_HOST = API_HOST
  ? `https://${API_HOST}`
  : process.env.REACT_APP_API_HOST;

class API {
  checkJwtIsValid() {
    if (!Cookie.getItem('jwt')) {
      window.location.href = '/';
      return false;
    }
    return true;
  }

  get({ endpoint, params, headers }) {
    if (endpoint !== 'auth') {
      this.checkJwtIsValid();
    }

    return this.request({
      method: 'GET',
      endpoint,
      params,
      headers,
    });
  }

  put({ endpoint, body, params, headers }) {
    this.checkJwtIsValid();

    return this.request({
      method: 'PUT',
      endpoint,
      body,
      params,
      headers,
    });
  }

  async request({ method, endpoint, params, body, headers }) {
    const query = params ? `?${paramString(params)}` : '';
    const url = `${REQUEST_HOST}/api/${endpoint}${query}`;

    try {
      const response = await fetch(url, {
        method,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Jwt-Token': Cookie.getItem('jwt'),
          'X-Customer-Id': 0,
          ...headers,
        },
        body: JSON.stringify(body),
      });
      // if you're receiving one of those - you're not an admin
      if (
        (response.status === 401 || response.status === 403) &&
        endpoint !== 'auth'
      ) {
        Cookie.removeItem('jwt');
        window.location.href = '/';
      }
      return response.json();
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
}

export default new API();
