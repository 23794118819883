import Actions from './Actions';
import { UserSource } from '../../sources';
import alt, { createHMRStore } from '../../alt';
import User from '../../records/User';
import UserDataStore from '../../data_stores/UserDataStore';

export const ROLES_TAB = 0;

const ALL_TABS = [ROLES_TAB];

export const HASH_TO_TAB = {
  '#roles': ROLES_TAB,
};

const TAB_TO_HASH = {
  [ROLES_TAB]: '#roles',
};

enum Tabs {
  '#roles' = 0,
}

interface state {}

class UsersShowStore {
  // work around for bindListeners
  [x: string]: any;

  userId: number | null = null;

  loading = false;

  editingContactInfo = false;

  originalUser: User = new User();

  editingUser: any; // User = new User();

  // bindListeners: ({}: any) => void;
  selectedTab: any;
  // waitFor: any;

  constructor() {
    // this.selectTabFromUrl();

    this.bindListeners({
      mounted: Actions.mounted,
      // tabSelected: AdminPageActions.tabSelected,
      enableContactInfoEditing: Actions.contactInfoEditEnabled,
      cancelContactInfoEditing: Actions.contactInfoEditCancelled,
      commitContactInfoEditing: Actions.contactInfoEditCommitted,
      userUpdated: Actions.userUpdated,
    });
  }

  mounted(id: string) {
    this.userId = parseInt(id, 10);
    this.getUser(this.userId);
    // this.selectTabFromUrl();
  }

  getUser(id: number) {
    this.loading = true;

    UserSource.fetch({
      id,
      success: Actions.userFetchSuccess,
      // error: Actions.userFetchError,
    });
  }

  userFetchSuccess(_user: User) {
    this.loading = false;
  }

  userFetchError(...args: any[]) {
    this.loading = false;
    // this.notifyError('error fetching event', args); //this does not exist in
  }

  tabSelected(newTab: any) {
    if (!ALL_TABS.includes(newTab)) {
      return;
    }

    this.selectedTab = newTab;
    // CPP not sure this is correct?
    // window.location.hash = TAB_TO_HASH[this.selectedTab];
    window.location.hash = Tabs[this.selectedTab];
  }

  selectTabFromUrl() {
    // CPP not sure this is correct?
    // this.selectedTab = HASH_TO_TAB[window.location.hash];
    this.selectedTab = Tabs[<any>window.location.hash];
  }

  enableContactInfoEditing() {
    this.waitFor(UserDataStore);

    this.editingContactInfo = true;
    this.originalUser = UserDataStore.getState().records.get(
      this.userId,
      new User()
    );

    this.editingUser = this.originalUser;
  }

  cancelContactInfoEditing() {
    this.editingContactInfo = false;
  }

  commitContactInfoEditing() {
    UserSource.update({
      user: this.editingUser,
      success: () => {},
      error: () => {},
    });

    this.cancelContactInfoEditing();
  }

  userUpdated({ keypath, value }: { keypath: any; value: any }) {
    // TODO: How should we actually handle nested object updates like this.
    this.editingUser = this.editingUser.setIn(keypath, value);
  }
}

export default createHMRStore(UsersShowStore, 'UsersShowStore');
