import { Record } from 'immutable';

class Address extends Record({
  id: null,
  city: null,
  line_1: null,
  line_2: null,
  line_3: null,
  postal_code: null,
  state: null,
}) {
  isBlank() {
    return (
      !this.line_1 &&
      !this.line_2 &&
      !this.line_3 &&
      !this.city &&
      !this.state &&
      !this.postal_code
    );
  }
}

export default Address;
