import { Record } from 'immutable';
import Address from './Address';

class User extends Record({
  id: null,
  address: new Address(),
  date_of_birth: null,
  email: null,
  first_name: null,
  gender: null,
  get_email_notifications: true,
  last_login_at: null,
  registered_at: null,
  invite_sent_at: null,
  last_name: null,
  managed_by_id: null,
  phone: null,
  ssn_last_4: null,
  time_zone: null,
}) {
  constructor(obj = {}) {
    const address = obj.address ? new Address(obj.address) : new Address();

    super(Object.assign(obj, { address }));
  }

  toServer() {
    const data = this.toJS();

    if (new Address(data.address || {}).isBlank()) {
      delete data.address;
    }

    if (!data.email || !data.email.trim().length) {
      delete data.email;
    }

    return data;
  }
}

export default User;
