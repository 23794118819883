import { List } from 'immutable';
import { createHMRStore } from '../../alt';

import Actions from './Actions';
import { UserSource } from '../../sources';

class UsersStore {
  constructor() {
    this.userIds = List();
    this.search = '';
    this.loading = false;

    this.bindListeners({
      mounted: Actions.mounted,
      usersListSuccess: Actions.usersListSuccess,
      searchUpdated: Actions.searchUpdated,
    });
  }

  listUsers() {
    UserSource.list({
      params: { search: this.search },
      success: Actions.usersListSuccess,
      error: Actions.usersListError,
    });
  }

  mounted() {
    this.loading = true;
    this.listUsers();
  }

  usersListSuccess({ records }) {
    this.userIds = records.map(r => r.id);
    this.loading = false;
  }

  searchUpdated(value) {
    this.search = value;
    this.listUsers();
  }
}

export default createHMRStore(UsersStore, 'UsersStore');
