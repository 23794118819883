import alt from '../../alt';

class LoginActions {
  constructor() {
    this.generateActions(
      'emailUpdated',
      'passwordUpdated',
      'login',
      'loginSuccess',
      'loginError'
    );
  }
}

export default alt.createActions(LoginActions);
