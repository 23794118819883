import * as React from 'react';
import { Grid, Typography } from '@upperhand/playmaker';

const styles = {
  root: {
    border: '0.69px solid #E2E2E2',
    padding: '8px 8px 8px 8px',
  },
};

const TableHeader = () => {
  return (
    <div style={styles.root}>
      <Grid container direction="row" justify="flex-start">
        <Grid item xs={6}>
          <Typography variant="subtitle2">Name</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2">Email</Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default TableHeader;
