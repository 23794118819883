import * as React from 'react';
import AltContainer from 'alt-container';
import { Grid, Spinner } from '@upperhand/playmaker';
import { withRouter } from 'react-router-dom';

import './Users.css';
import Actions from './Actions';
import UsersStore from './Store';
import UserDataStore from '../../data_stores/UserDataStore';
import Table from './components/Table';
import Filters from './components/Filters';

const styles = {
  root: {
    // padding: '47px 0',
  },
};

const Content = withRouter(
  ({ history, usersStore: { userIds, search, loading } }) => {
    React.useEffect(() => {
      Actions.mounted();
    }, []);

    return (
      <div className="users" style={styles.root}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Filters search={search} />
          </Grid>
          {loading ? (
            <Grid item xs={12}>
              <div style={{ textAlign: 'center' }}>
                <Spinner type="indeterminate" />
              </div>
            </Grid>
          ) : (
            <>
              <Grid item xs={12}>
                <Table
                  userIds={userIds}
                  onUserSelect={id => history.push(`/users/${id}`)}
                />
              </Grid>
              <Grid item xs={12}>
                {/* <Paginator
              currentPage={page}
              perPage={perPage}
              totalCount={totalCount}
              onPageSelect={ClientsActions.pageSelected}
            /> */}
              </Grid>
            </>
          )}
        </Grid>
      </div>
    );
  }
);

const Users = props => (
  <AltContainer
    stores={{ usersStore: UsersStore, userDataStore: UserDataStore }}
  >
    <Content {...props} />
  </AltContainer>
);

export default Users;
