import { Map } from 'immutable';

import { UserActions } from '../sources';
import { createHMRStore } from '../alt';

class UserDataStore {
  constructor() {
    this.records = Map();

    this.bindListeners({
      fetchSuccess: [UserActions.fetchSuccess, UserActions.updateSuccess],
      listSuccess: UserActions.listSuccess,
      listError: UserActions.listError,
    });
  }

  fetchSuccess(record) {
    this.records = this.records.set(record.id, record);
  }

  listSuccess({ records }) {
    const fetchedRecords = records.groupBy(r => r.id).map(r => r.first());

    this.records = this.records.merge(fetchedRecords);
  }

  // NOTE: Alt requires class methods
  // eslint-disable-next-line class-methods-use-this
  listError() {
    console.log('Error fetching users.');
  }
}

export default createHMRStore(UserDataStore, 'UserDataStore');
