import * as React from 'react';
import { Grid, Tabs, Card } from '@upperhand/playmaker';

import './UsersShow.css';
import User from '../../records/User';
import Sidebar from './components/Sidebar';

import UserDataStore from '../../data_stores/UserDataStore';
import UsersShowActions from './Actions';
import UsersShowStore from './Store';
import { altContainerFactory } from '../../alt';

const UsersShow = ({
  usersShowStore: { userId, editingContactInfo, editingUser },
  userDataStore: { records: users },
  match,
}) => {
  React.useEffect(() => {
    UsersShowActions.mounted(parseInt(match.params.userId, 10));
  }, [match.params.userId]);

  const user = users.get(userId, new User());

  const tabs = [
    {
      label: 'Roles',
      content: (
        <div style={{ paddingTop: 8 }}>
          <Card>Coming Soon</Card>
        </div>
      ),
    },
  ];

  return (
    <div className="UsersShow">
      <Grid container spacing={1}>
        <Grid item xs={12} lg={4}>
          <Sidebar
            editingContactInfo={editingContactInfo}
            editingUser={editingUser}
            onContactEdit={UsersShowActions.contactInfoEditEnabled}
            onContactEditCancel={UsersShowActions.contactInfoEditCancelled}
            onContactEditCommit={UsersShowActions.contactInfoEditCommitted}
            onUserUpdate={UsersShowActions.userUpdated}
            user={user}
          />
        </Grid>
        <Grid item xs={12} lg={8}>
          <div className="tabContainer">
            <Tabs onChange={() => {}} tabItems={tabs} value={0} />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default altContainerFactory({
  stores: {
    usersShowStore: UsersShowStore,
    userDataStore: UserDataStore,
  },
})(UsersShow);
