import { List } from 'immutable';

class Parser {
  constructor({ type, paginationKey }) {
    this.type = type;

    if (paginationKey) this.paginationKey = paginationKey;
  }

  parseNone(data) {
    return data;
  }

  parseSingle(data) {
    return new this.type(data, { fromServer: true });
  }

  parseCollection(data) {
    return List(data.map(d => this.parseSingle(d)));
  }

  parsePagination(data) {
    return {
      records: this.parseCollection(data[this.paginationKey]),
      page: data.page,
      perPage: data.per_page,
      totalCount: data.total_count,
    };
  }
}

export default Parser;
