import * as React from 'react';
import { Button, Grid, Typography } from '@upperhand/playmaker';

import User from '../../records/User';

import './AppHeader.css';
import UserDataStore from '../../data_stores/UserDataStore';
import AppHeaderActions from './Actions';
import AppHeaderStore from './Store';
import { altContainerFactory } from '../../alt';

const AppHeader = ({
  appHeaderStore: { userId },
  userDataStore: { records: users },
}) => {
  React.useEffect(() => {
    AppHeaderActions.mounted();
  }, []);

  const user = users.get(userId, new User());

  return (
    <div className="AppHeader">
      <Grid
        container
        justify="flex-end"
        alignItems="center"
        spacing={1}
        xs={12}
      >
        <Grid item>
          <Typography variant="subtitle2">
            {user.first_name} {user.last_name}
          </Typography>
        </Grid>

        <Grid item>
          <Button type="tertiary" onClick={AppHeaderActions.signOut}>
            Sign Out
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default altContainerFactory({
  stores: {
    appHeaderStore: AppHeaderStore,
    userDataStore: UserDataStore,
  },
})(AppHeader);
