import * as React from 'react';
import { Card, Grid, Typography } from '@upperhand/playmaker';

import { altContainerFactory } from '../../../alt';
import UserDataStore from '../../../data_stores/UserDataStore';

const UserCard = ({ userId, onClick, userDataStore: { records: users } }) => {
  const user = users.get(userId);

  if (!user) {
    return null;
  }

  return (
    <Card contentDirection="row" onClick={() => onClick(userId)}>
      <Grid container alignItems="center" xs={12} spacing={1}>
        <Grid container item alignItems="center" xs={12} md={6}>
          <Grid item>
            <Typography>
              {user.first_name} {user.last_name}
            </Typography>
          </Grid>
        </Grid>
        <Grid item alignItems="center" xs={12} md={6}>
          <Typography>{user.email}</Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

export default altContainerFactory({
  stores: {
    userDataStore: UserDataStore,
  },
})(UserCard);
