import * as React from 'react';
import { Card, Grid, Typography } from '@upperhand/playmaker';

const EmptyState = ({ filtersPresent }) => {
  return (
    <Card>
      <Grid container justify="center">
        <Grid item>
          <Typography>
            {filtersPresent ? 'No results found.' : 'No users.'}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

export default EmptyState;
