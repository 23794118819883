import jwtDecode from 'jwt-decode';

import { createHMRStore } from '../../alt';

import LoginActions from './Actions';
import API from '../../Api';
import Cookie from '../../utils/Cookie';

class LoginStore {
  constructor() {
    this.email = '';
    this.password = '';
    this.error = '';
    this.authenticating = false;

    this.bindListeners({
      emailUpdated: LoginActions.emailUpdated,
      passwordUpdated: LoginActions.passwordUpdated,
      login: LoginActions.login,
      loginSuccess: LoginActions.loginSuccess,
      loginError: LoginActions.loginError,
    });
  }

  emailUpdated(value) {
    this.email = value;
    this.error = '';
  }

  passwordUpdated(value) {
    this.password = value;
    this.error = '';
  }

  login() {
    this.authenticating = true;

    API.get({
      endpoint: 'auth',
      headers: {
        Authorization: `Basic ${btoa(`${this.email}:${this.password}`)}`,
      },
    })
      .then(data => LoginActions.loginSuccess(data))
      .catch(error => LoginActions.loginError(error));
  }

  // eslint-disable-next-line class-methods-use-this
  loginSuccess(token) {
    if (token) {
      const { exp: jwtExp } = jwtDecode(token);
      const cookieExpDate = new Date(jwtExp * 1000);

      Cookie.setItem('jwt', token, cookieExpDate);
      window.location.reload();
    } else {
      Cookie.removeItem('jwt');
    }
  }

  // TODO: this error message should be less generic.
  loginError(_err) {
    this.authenticating = false;
    this.error = 'Username or password is incorrect.';
  }
}

export default createHMRStore(LoginStore, 'LoginStore');
