import * as React from 'react';
import { Typography } from '@upperhand/playmaker';

const Address = ({ address }) => (
  <>
    <Typography variant="subtitle2">{address.line_1}</Typography>
    <Typography variant="subtitle2">{address.line_2}</Typography>
    <Typography variant="subtitle2">
      {address.city}, {address.state} {address.postal_code}
    </Typography>
  </>
);

export default Address;
