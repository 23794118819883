import User from '../records/User';
import Parser from './Parser';
import DataStoreActions from './UserActions';
import API from '../Api';

const parserTo = { type: User, paginationKey: 'users' };

const parser = new Parser(parserTo);

export const fetch = ({ id, success }) => {
  API.get({ endpoint: `users/${id}` })
    .then(data => {
      const parsedData = parser.parseSingle(data);

      DataStoreActions.fetchSuccess(parsedData);
      success(parsedData);
    })
    .catch(data => console.log(data));
};

export const list = ({ params, success, error }) => {
  API.get({ endpoint: 'users', params })
    .then(data => {
      const parsedData = parser.parsePagination(data);

      DataStoreActions.listSuccess(parsedData);
      success(parsedData);
    })
    .catch(data => error(data));
};

export const update = ({ user, success, error }) => {
  API.put({
    endpoint: `users/${user.id}`,
    body: { attributes: user.toServer() },
  })
    .then(data => {
      const parsedData = parser.parseSingle(data);

      DataStoreActions.updateSuccess(parsedData);
      success(parsedData);
    })
    .catch(data => error(data));
};
