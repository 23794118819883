import { createHMRStore } from '../../alt';

import API from '../../Api';
import Actions from './Actions';

class ClientsStore {
  constructor() {
    this.clients = [];

    this.bindListeners({
      mounted: Actions.mounted,
      clientsListSuccess: Actions.clientsListSuccess,
    });
  }

  // NOTE: Alt requires class methods
  // eslint-disable-next-line class-methods-use-this
  mounted() {
    API.get({ endpoint: 'clients' })
      .then(data => Actions.clientsListSuccess(data))
      .catch(error => Actions.clientsListError(error));
  }

  clientsListSuccess({ clients }) {
    this.clients = clients;
  }
}

export default createHMRStore(ClientsStore, 'ClientsStore');
