import jwtDecode from 'jwt-decode';
import Actions from './Actions';
import { UserSource } from '../../sources';
import { createHMRStore } from '../../alt';
import Cookie from '../../utils/Cookie';

class AppHeaderStore {
  constructor() {
    this.userId = null;
    this.loading = false;

    this.bindListeners({
      mounted: Actions.mounted,
      userFetchSuccess: Actions.userFetchSuccess,
      signOut: Actions.signOut,
    });
  }

  mounted() {
    this.userId = jwtDecode(Cookie.getItem('jwt')).sub;
    this.getUser(this.userId);
  }

  getUser(id) {
    this.loading = true;

    UserSource.fetch({
      id,
      success: Actions.userFetchSuccess,
      error: Actions.userFetchError,
    });
  }

  userFetchSuccess(_user) {
    this.loading = false;
  }

  userFetchError(...args) {
    this.loading = false;
    this.notifyError('error fetching current user', args);
  }

  // NOTE: Alt requires class methods
  // eslint-disable-next-line class-methods-use-this
  signOut() {
    Cookie.removeItem('jwt');
    Cookie.removeItem('role');
    window.location = '/';
  }
}

export default createHMRStore(AppHeaderStore, 'AppHeaderStore');
