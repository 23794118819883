import * as React from 'react';
import AltContainer from 'alt-container';
import Alt from 'alt/src/index';

const alt = new Alt();

window.alt = alt;

export const createHMRStore = (
  storeModel,
  storeName = storeModel.displayName,
  ...args
) => {
  if (module.hot) {
    const currentStore = alt.stores[storeName];

    // If this store is already defined. Unbind all its listeners so the we don't
    // respond to the same actions multiple times.
    if (currentStore) {
      currentStore.state.actionListeners = {};
      currentStore.boundListeners = [];
    }
  }

  return alt.createStore(storeModel, storeName, ...args);
};

export const altContainerFactory = ({
  stores = {},
  store = undefined,
  actions = {},
}) => {
  return function altContainer(WrappedComponent) {
    class Component extends React.PureComponent {
      render() {
        return (
          <AltContainer stores={stores} store={store} actions={actions}>
            <WrappedComponent {...this.props} />
          </AltContainer>
        );
      }
    }

    return Component;
  };
};

export default alt;
