import * as React from 'react';
import { Hidden, Grid } from '@upperhand/playmaker';

import EmptyState from './EmptyState';
import TableHeader from './TableHeader';
import UserCard from './UserCard';

const Table = ({ userIds, onUserSelect, filtersPresent }) => {
  return (
    <div>
      <Grid item container spacing={1}>
        <Hidden mdDown>
          <Grid item md={12}>
            <TableHeader />
          </Grid>
        </Hidden>
        {userIds.map(id => {
          return (
            <Grid item key={id} xs={12}>
              <UserCard userId={id} onClick={onUserSelect} />
            </Grid>
          );
        })}
        {userIds.isEmpty() && (
          <Grid item xs={12}>
            <EmptyState filtersPresent={filtersPresent} />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default Table;
