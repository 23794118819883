import * as React from 'react';
import AltContainer from 'alt-container';

import './Clients.css';
import Actions from './Actions';
import ClientsStore from './Store';

const Content = ({ clientsStore: { clients } }) => {
  React.useEffect(() => {
    Actions.mounted();
  }, []);

  return (
    <div className="clients">
      {clients.map(c => (
        <div>
          {c.id} {c.first_name} {c.last_name}
        </div>
      ))}
    </div>
  );
};

const Clients = props => (
  <AltContainer stores={{ clientsStore: ClientsStore }}>
    <Content {...props} />
  </AltContainer>
);

export default Clients;
